import React, { ReactElement, useMemo, useState } from 'react';

const initState = {
  score: 0,
  quizQty: 20,
  updateScore: () => {},
  resetScore: () => {},
  setQuizQty: (qty: number) => {},
};
  
type ProviderProps = {
  children: ReactElement;
};

export const ScoreContext = React.createContext(initState);

const ProviderScore = ({ children }: ProviderProps) => {
  const [state, setState] = useState(initState);
  const updateScore = () => {  
    setState({ ...state, score: state.score + 1 });     
  };

  const resetScore = () => {
    setState({ ...state, score: 0 });
  };

  const setQuizQty = (qty: number) => {
    setState({ ...state, quizQty: qty });
  };

  const value = useMemo(() => ({ ...state, updateScore, resetScore, setQuizQty }), [state]);
    
  return (
    <ScoreContext.Provider value={value}>{children}</ScoreContext.Provider>
  );
};

export default ProviderScore;
