import React, { ReactElement } from 'react';
import { Button, Card, Col, Row } from 'antd';
import {
  OrderedListOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import styles from './Main.module.scss';
import Share from '../Share/Share';
import { Link } from 'react-router-dom';

const Main = (): ReactElement => {

  return (
    <>

          <h1 className={styles.heading}>Be a Personal Finance Unicorn</h1>
      <Row gutter={[24, 24]}>
        <Col span={24} sm={12}>
          <Card
            cover={
              <>
              <img
                src={require('../../photos/quiz-500.jpg')}
                alt='Multiple Choice Quiz'
                className={styles.responsiveImg}
              />
              <small>Photo by <a href="https://unsplash.com/@nguyendhn?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nguyen Dang Hoang Nhu</a> on <a href="https://unsplash.com/s/photos/quiz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></small>
              </>
            }
            bordered={false}
          >
            <div className={styles.cardBody}>
              <h2>Take the most comprehensive and challenging Canadian personal finance quiz.</h2>
              <Link to="/canadian-personal-finance-quiz">
              <Button
                type="primary"
                icon={<OrderedListOutlined />}
                size="middle"
                style={{ backgroundColor: '#29a60d', borderColor: '#29a60d' }}
              >
                Launch Quiz
              </Button>
              </Link>
            </div>
          </Card>
        </Col>
        <Col span={24} sm={12}>
          <Card
            cover={
              <>
              <img
                src={require('../../photos/learn-500.jpg')}
                alt='Love To Learn Signage'
                className={styles.responsiveImg}
              />
              <small>Photo by <a href="https://unsplash.com/@timmossholder?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Tim Mossholder</a> on <a href="https://unsplash.com/s/photos/learn?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></small>
              </>
            }
            bordered={false}
          >
            <div className={styles.cardBody}>
              <h2>Free resources that help increase your financial knowledge.</h2>
              <a href="https://learn.unicornmoney.ca" target="_blank">
                <Button type="primary" icon={<ReadOutlined />} size="middle" style={{ backgroundColor: '#29a60d', borderColor: '#29a60d' }}>
                  Learning Guide
                </Button>
              </a>
            </div>
          </Card>
        </Col>
      </Row>
      <Share />
    </>
  );
};

export default Main;
