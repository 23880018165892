import { Divider } from 'antd';
import React from 'react';
import { RiArticleLine } from 'react-icons/ri';
import { HiOutlineLibrary } from 'react-icons/hi';
import { FiYoutube } from 'react-icons/fi';
import { MdOutlineQuiz } from 'react-icons/md';
import { GiNewspaper } from 'react-icons/gi';
import styles from './About.module.scss';

const About = () => {
  return (
    <article className={styles.about}>
      <Divider className={styles.title}>ABOUT</Divider>
      <h2 className={styles.h2}>
        How do I teach my family about personal finance?
      </h2> 
      <br />
      <p>
        Hello everyone! Welcome to Unicorn Money! I am a married father of 2
        young kids and am an IT professional living in Calgary. This is the question that I've been thinking about for years for my family and this site was born. Personal finance is daunting as there are so many aspects of it. It is not simply about what stock do I buy? I believe that obtaining knowledge in personal finance is critical for everyone, regardless of what you do. Even if you intend to hire a financial planner/advisor, it is important to understand the topics so you can make the best decision. I believe that this site can help you be a personal finance unicorn!
      </p>
      <h3 className={styles.h3}>The site has 2 main features:</h3>

      <div className={styles.feature}>
        <div className={styles.featureContent}>
          <span className={styles.icon}>
            <MdOutlineQuiz />
          </span>
          <p className={styles.description}>
            The most comprehensive and challenging quiz that will test your
            knowledge on various personal finance topics
          </p>
        </div>
        <div className={styles.featureContent}>
          <span className={styles.icon}>
            <HiOutlineLibrary />
          </span>
          <p className={styles.description}>
            A learning guide which is a carefully crafted list of FREE financial
            education resources which I have learned from throughout the years.
            The majority of resources I selected are ones that can be easily
            understood without needing a finance background.
          </p>
        </div>
      </div>
      <h3 className={styles.h3}>The learning guide consists of:</h3>
      <div className={styles.feature}>
        <div className={styles.featureContent}>
          <span className={styles.icon}>
          <RiArticleLine />
          </span>
          <p className={styles.description}>
            Short articles - most can be read in 10 minutes or less
          </p>
        </div>
        <div className={styles.featureContent}>
          <span className={styles.icon}>
            <FiYoutube />
          </span>
          <p className={styles.description}>
            Short Videos - most can be viewed in 10 minutes or less
          </p>
        </div>
        <div className={styles.featureContent}>
          <span className={styles.icon}>
            <GiNewspaper />
          </span>
          <p className={styles.description}>Research papers - for those who have a deep interest in finance</p>
        </div>
      </div>
    </article>
  );
};

export default About;
