import React from 'react';
import { Collapse, Divider } from 'antd';
import { MdLocalLibrary, MdLocalGroceryStore } from 'react-icons/md';
import styles from './Favorites.module.scss';
import { articles, best, books, calculators, podcasts, videos } from './data';

const Favorites = () => {
  const { Panel } = Collapse;

  const listBuilder = (
    lists: { name: string; url: string }[],
  ): React.ReactNode => {
    return (
      <ul>
        {lists
          .sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          })
          .map((item) => {
            return (
              <li key={item.name}>
                <a href={item.url} target="_blank">
                  {item.name}
                </a>
                <Divider />
              </li>
            );
          })}
      </ul>
    );
  };

  const bookBuilder = (
    lists: { name: string; author: string; libUrl: string }[],
  ): React.ReactNode => {
    return (
      <ul>
        {lists
          .sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          })
          .map((item) => {
            return (
              <li key={item.name}>
                {`${item.name} by ${item.author}`}
                <a href={item.libUrl} target="_blank" className={styles.icon}>
                <MdLocalLibrary />
                </a>
                <a href={`https://www.chapters.indigo.ca/en-ca/home/search/?keywords=${item.name} ${item.author}`} target="_blank" className={styles.icon}>
                <MdLocalGroceryStore />
                </a>
                <Divider />
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <article className={styles.favorites}>
      <Divider className={styles.h1}>MY FAVORITES</Divider>
      <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6']}>
        <Panel header="Articles" key="1">
          {listBuilder(articles)}
        </Panel>
        <Panel header="Books" key="2">
          {bookBuilder(books)}
        </Panel>
        <Panel header="Best Lists" key="3">
          {listBuilder(best)}
        </Panel>
        <Panel header="Calculators" key="4">
          {listBuilder(calculators)}
        </Panel>
        <Panel header="Podcasts" key="5">
          {listBuilder(podcasts)}
        </Panel>
        <Panel header="Videos" key="6">
          {listBuilder(videos)}
        </Panel>
      </Collapse>
    </article>
  );
};

export default Favorites;
