import React, { useContext } from 'react';
import { ScoreContext } from '../ProviderScore/ProviderScore';
import styles from './FinalScore.module.scss';

const FinalScore = ({ numberOfQuestions }: { numberOfQuestions: number }) => {
  const { score } = useContext(ScoreContext);
  const scorePercent = Math.round((score / numberOfQuestions) * 100);

  console.log(scorePercent);

  const message = () => {
    if (scorePercent <= 40) {
      return 'You have achieved Baby Unicorn status. Do not get discouraged, just like learning any topic, it takes time to gain knowledge in personal finance. Checkout the Learning Guide to increase your knowledge.';
    }
    if (scorePercent >= 40 && scorePercent <= 80) {
      return 'You have achieved Junior Unicorn status. You will get there, just continue making progress. Checkout the Learning Guide to increase your knowledge.';
    }
    if (scorePercent >= 80) {
      return 'You have achieved King Unicorn status. Keep abreast with the latest changes and products in personal finance. Checkout the Learning Guide to  increase your knowledge.';
    }
  };

  return (
    <div className={styles.scoreContainer}>
      <h2>Your score is {scorePercent}%</h2>
      <p>{message()}</p>
    </div>
  );
};

export default FinalScore;
