import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { OrderedListOutlined } from '@ant-design/icons';
import { Button, Divider, Modal } from 'antd';
import FinalScore from '../FinalScore/FinalScore';
import { ScoreContext } from '../ProviderScore/ProviderScore';
import QuizContainer from '../QuizContainer/QuizContainer';
import styles from './Quiz.module.scss';

const Quiz = () => {
  const [loadQuiz, setLoadQuiz] = useState(false);
  const [showScore, setShowScore] = useState(false);
  const { quizQty, resetScore, setQuizQty } = useContext(ScoreContext);
  const scoreBtnOnClick = () => {
    setLoadQuiz(false);
    setShowScore(true);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onInputChangeHandler = (event: any) => {
    const regex = new RegExp('^[1-4][0-9]?$|^5$|^50$');

    if (event.target.value === '') {
      setQuizQty(1);
    }
    if (regex.test(event.target.value)) {
      setQuizQty(event.target.value);
    }
  };
  const quizButtonOnHandler = () => {
    resetScore();
    setLoadQuiz(true);
  };
  return (
        <>
        <Helmet>
            <title>The most comprehensive Canadian personal finance quiz</title>
            <meta name="description" content="The most comprehensive Canadian personal finance quiz" />
        </Helmet>
        <div className={styles.quizQty}>
        <Divider className={styles.title}>QUIZ</Divider>
            <h1>The most comprehensive Canadian personal finance quiz</h1>
            <p>What makes this quiz different from the other personal finance quizes out there?</p>
            <p>Some of the questions are very specific with the numbers. You need to do some research to be able to answer the questions correctly. I encourage you to use Google or the resources in the <a href="https://learn.unicornmoney.ca" target="_blank">learning guide</a> to find the answers. Few people are able to memorize all the facts and figures (ie. tax rates, withdrawal rates, etc.) and various rules of the different account types, etc. A big factor of personal finance is knowing how to do calculations, and how to find information. </p>
                <span>How many questions would you like (max of 50)?</span>
                <input
                  type="number"
                  defaultValue={20}
                  value={quizQty}
                  max={50}
                  onChange={onInputChangeHandler}
                />
              <Button
                type="primary"
                icon={<OrderedListOutlined />}
                onClick={quizButtonOnHandler}
                size="middle"
                style={{ width: '150px', backgroundColor: '#29a60d', borderColor: '#29a60d', marginBottom: '20px' }}
              >
                Launch Quiz
              </Button>
              </div>
<Modal
        title="Quiz"
        visible={loadQuiz}
        onOk={() => {
          setLoadQuiz(false);
        }}
        onCancel={() => {
          setLoadQuiz(false);
        }}
        maskClosable={false}
        footer={null}
        destroyOnClose
      >
        <QuizContainer scoreBtnOnClick={scoreBtnOnClick} />
      </Modal>
      <Modal
        title="Final Score"
        visible={showScore}
        onOk={() => {
          setShowScore(false);
        }}
        onCancel={() => {
          setShowScore(false);
        }}
        maskClosable={false}
        footer={null}
        destroyOnClose
      >
        <FinalScore numberOfQuestions={quizQty} />
      </Modal>
      </>
  );
};

export default Quiz;