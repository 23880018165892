import React from 'react';
import './App.scss';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import ProviderScore from './components/ProviderScore/ProviderScore';
import About from './components/About/About';
import Feedback from './components/Feedback/Feedback';
import { Content } from 'antd/lib/layout/layout';
import Favorites from './components/Favorites/Favorites';
import Quiz from './components/Quiz/Quiz';

function App() {
  return (
    <Router>
      <div className="App">
        <Layout className="layout">
          <Navbar />
          <Content
            style={{
              padding: '0 50px',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              backgroundColor: 'white',
              minHeight: '800px',
            }}
          >
            <Routes>
              <Route path="/about" element={<About />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/favorites" element={<Favorites />} />
              <Route path="/canadian-personal-finance-quiz" element={<ProviderScore><Quiz /></ProviderScore>} />
              <Route
                path="*"
                element={
                  <ProviderScore>
                    <Main />
                  </ProviderScore>
                }
              />
            </Routes>
          </Content>
          <Footer />
        </Layout>
      </div>
    </Router>
  );
}

export default App;
