import {
  ShareAltOutlined,
  FacebookFilled,
  TwitterSquareFilled,
} from '@ant-design/icons';
import { Button, Col, Divider, Row } from 'antd';
import styles from './Share.module.scss';
import React from 'react';

const Share = () => {
  const onShareClickHandler = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Unicorn Money',
        url: 'https://www.unicornmoney.ca',
      });
    }
  };
  return (
    <Row>
      <Col span={24}>
        <Divider className={styles.title}>SHARE</Divider>
        <div className={styles.share}>
          <div>
            <Button
              icon={<ShareAltOutlined />}
              onClick={onShareClickHandler}
              type="primary"
              size="small"
              style={{ backgroundColor: '#29a60d', borderColor: '#29a60d' }}
            >
              Share
            </Button>
          </div>
          <div
            className="fb-share-button"
            data-href="https://www.unicornmoney.ca"
            data-layout="button_count"
            data-size="large"
          >
            <a
              target="_blank"
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.unicornmoney.ca%2F&amp;src=sdkpreparse"
              className="fb-xfbml-parse-ignore"
            >
              <span className={styles.icon}><FacebookFilled /></span>
            </a>
          </div>
          <a
            target="_blank"
            href="https://twitter.com/share?ref_src=twsrc%5Etfw"
            className="twitter-share-button"
            data-text="The most comprehensive Canadian personal finance quiz"
            data-url="https://www.unicornmoney.ca"
            data-show-count="false"
          >
            <span className={styles.icon}><TwitterSquareFilled /></span>
          </a>
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charSet="utf-8"
          ></script>
        </div>
      </Col>
    </Row>
  );
};

export default Share;
