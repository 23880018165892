import React, { ReactElement } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import styles from './Navbar.module.scss';

const Navbar = (): ReactElement => {
  const { Header } = Layout;

  return (
    <Header className={styles.header}>
      <Link to="/">
      <img
        src={require('../../photos/unicornmoney.png')}
        alt="UnicornMoney Logo"
        className={styles.icon}
      />
      </Link>
      <Menu theme="light" mode="horizontal" defaultSelectedKeys={['SubMenu']}>
        <Menu.SubMenu
          key="SubMenu"
          icon={
            <span className={styles.svg}>
              <MenuOutlined />
            </span>
          }
        >
          <Menu.Item key="1">
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/about">About</Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/favorites">Favorites</Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/feedback">Feedback</Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to="/canadian-personal-finance-quiz">Quiz</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Header>
  );
};

export default Navbar;
