import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  CaretRightOutlined,
  FormOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import QuizItem from '../QuizItem/QuizItem';
import styles from './QuizContainer.module.scss';
import { ScoreContext } from '../ProviderScore/ProviderScore';
import generateQuestions from '../../Questions';

type QuizContainerProps = {
  scoreBtnOnClick: () => void;
};

const QuizContainer = ({
  scoreBtnOnClick,
}: QuizContainerProps): ReactElement => {
  const [answer, setAnswer] = useState<number>(-1);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  const { updateScore, quizQty } = useContext(ScoreContext);

  const onRadioClick = (value: number) => {
    setAnswer(value);
  };
  const [quizItems, setQuizItems] = useState(generateQuestions().splice(0, quizQty));

  const [currentQuiz, setCurrentQuiz] = useState(
    <QuizItem
      questionNumber={currentQuestionNumber + 1}
      question={quizItems[0].question}
      choices={quizItems[0].choices}
      selectedChoice={answer}
      correctAnswer={quizItems[0].answer}
      showAnswer={false}
      onClick={onRadioClick}
    />,
  );

  const submitBtnOnClick = () => {
    setShowAnswer(true);
    setCurrentQuiz(
      <QuizItem
        questionNumber={currentQuestionNumber + 1}
        question={quizItems[currentQuestionNumber].question}
        choices={quizItems[currentQuestionNumber].choices}
        correctAnswer={quizItems[currentQuestionNumber].answer}
        showAnswer={true}
        selectedChoice={answer}
        onClick={onRadioClick}
      />,
    );
  };

  const nextBtnOnClick = () => {
    setAnswer(-1);
    setShowAnswer(false);
    setCurrentQuiz(
      <QuizItem
        questionNumber={currentQuestionNumber + 2}
        question={quizItems[currentQuestionNumber + 1].question}
        choices={quizItems[currentQuestionNumber + 1].choices}
        correctAnswer={quizItems[currentQuestionNumber + 1].answer}
        showAnswer={false}
        selectedChoice={-1}
        onClick={onRadioClick}
      />,
    );
    setCurrentQuestionNumber(currentQuestionNumber + 1);
  };

  useEffect(() => {
    if (answer === quizItems[currentQuestionNumber].answer) {
      updateScore();
    }
  }, [showAnswer]);

  const displayButton = () => {
    if (!showAnswer && currentQuestionNumber <= quizItems.length - 1) {
      return (
        <Button
          type="primary"
          icon={<FormOutlined />}
          onClick={submitBtnOnClick}
          size="middle"
          disabled={answer === -1}
        >
          Submit Answer
        </Button>
      );
    }
    if (showAnswer && currentQuestionNumber <= quizItems.length - 2) {
      return (
        <Button
          type="primary"
          icon={<CaretRightOutlined />}
          onClick={nextBtnOnClick}
          size="middle"
        >
          Next
        </Button>
      );
    }
    return (
      <Button
        type="primary"
        icon={<SolutionOutlined />}
        onClick={scoreBtnOnClick}
        size="middle"
      >
        Show Score
      </Button>
    );
  };

  return (
    <div className={styles.container}>
      {`Question ${currentQuestionNumber + 1} of ${quizItems.length}`}
      {currentQuiz}
      <div className={styles.buttons}>{displayButton()}</div>
    </div>
  );
};

export default QuizContainer;
