import _ from 'lodash';

const insuranceQs = [
  {
    category: 'Insurance',
    question: 'Which is the cheapest type of life insurance',
    choices: [
      'Term',
      'Whole',
      'Universal',
    ],
    answer: 0,
  },
  {
    category: 'Insurance',
    question: 'How often do premiums change in a term life insurance policy?',
    choices: [
      'Every year',
      'Every 5 years',
      'At renewal',
    ],
    answer: 2,
  },
  {
    category: 'Insurance',
    question: 'Which term length is NOT typical for term life insurance',
    choices: [
      '10',
      '100',
      '20',
      '30',
    ],
    answer: 1,
  },
  {
    category: 'Insurance',
    question: 'TRUE OR FALSE: When you cancel a term life insurance policy, the premiums that you have paid will be refunded to you',
    choices: [
      'TRUE - the cash surrender value will be returned',
      'FALSE - term life insurance does not have a cash surrender value',
    ],
    answer: 1,
  },
  {
    category: 'Insurance',
    question: 'Which type of permanent life insurance allows you to choose investments?',
    choices: [
      'Participating Whole',
      'Term-to-100',
      'Term',
      'Universal',
    ],
    answer: 3,
  },
  {
    category: 'Insurance',
    question: 'Which type of permanent life insurance can pay dividends?',
    choices: [
      'Participating Whole',
      'Term-to-100',
      'Term',
      'Universal',
    ],
    answer: 0,
  },
  {
    category: 'Insurance',
    question: 'TRUE OR FALSE: Investment options in a Universal life policy typically have a higher MER (Management Expense Ratios) compared to investments outside an insurance policy',
    choices: [
      'TRUE',
      'FALSE',
    ],
    answer: 0,
  },
];

const emergencyQs = [
  {
    category: 'Emergency Funds',
    question: 'What is the general guidance for how many months of expenses at a minimum should you save for your emergency fund?',
    choices: [
      '0-3 months',
      '3-6 months',
      '6-9 months',
      '9-12 months',
    ],
    answer: 1,
  },
  {
    category: 'Emergency Funds',
    question: 'Generally, what should you do with your emergency fund?',
    choices: [
      'Invest in stocks/bonds',
      'Invest in crypto currencies',
      'Keep it in a savings account',
      'Keep it in an RRSP',
    ],
    answer: 2,
  },
];

const creditQs = [
  {
    category: 'Credit',
    question: 'What are the 2 credit bureaus in Canada?',
    choices: [
      'Bank of Canada and Equifax',
      'Bank of Canada and TransUnion',
      'Royal Bank of Canada and Equifax',
      'TransUnion and Equifax',
    ],
    answer: 3,
  },
  {
    category: 'Credit',
    question: 'What is the maximum credit score for Equifax?',
    choices: [
      '800',
      '850',
      '900',
      '1000',
    ],
    answer: 2,
  },
  {
    category: 'Credit',
    question: 'Which is NOT a factor when calculating your credit score',
    choices: [
      'Credit history',
      'Payment history',
      'Credit inquiries',
      'Credit utilization',
      'Salary',
    ],
    answer: 4,
  },
  {
    category: 'Credit',
    question: 'Which is the biggest factor when calculating your credit score',
    choices: [
      'Credit history',
      'Payment history',
      'Credit inquiries',
      'Credit utilization',
    ],
    answer: 1,
  },
  {
    category: 'Credit',
    question: 'TRUE OR FALSE: I can obtain my credit score and report for free',
    choices: [
      'TRUE',
      'FALSE',
    ],
    answer: 0,
  },
];

const goalQs = [
  {
    category: 'Goals',
    question: 'With respect to goal setting, what does the SMART acronym stand for?',
    choices: [
      'Statistic, Manageable, Achievable, Relevant, Time-bound',
      'Specific, Measurable, Actionable, Relevant, Testable',
      'Specific, Measurable, Achievable, Relevant, Time-bound',
      'Specific, Measurable, Accounting, Relevant, Testable',
    ],
    answer: 2,
  },
  {
    category: 'Goals',
    question: 'TRUE OR FALSE: The 4% retirement withdrawal rule MUST be followed if you do not want to run out of money after 30 years',
    choices: [
      'TRUE - this has been tried and tested by millions of people',
      'FALSE - the rule is intended to be a guide, there are many factors that can impact your retirement portfolio returns',
    ],
    answer: 1,
  },
  {
    category: 'Goals',
    question: 'When can you create SMART financial goals?',
    choices: [
      'Once you get married',
      'Once you have kids',
      'When you are close to retirement',
      'Any time',
    ],
    answer: 3,
  },
];

const investQs = [
  {
    category: 'Investing',
    question: 'What is the target inflation % rate by the Bank of Canada?',
    choices: [
      '1%',
      '2%',
      '3%',
      '4%',
    ],
    answer: 1,
  },
  {
    category: 'Investing',
    question: 'What is the Consumer Price Index (CPI)?',
    choices: [
      'Tracks how much the average Canadian salary grows',
      'Tracks the return of the Toronto Stock Exchange (TSX)',
      "Tracks Canada's prime rate",
      'Tracks how much the average Canadian household spends',
    ],
    answer: 3,
  },
  {
    category: 'Investing',
    question: "Using the Bank of Canada's inflation calculator, $100 in 2012 would cost how much in 2022?",
    choices: [
      '110.22',
      '114.22',
      '124.41',
      '134.41',
    ],
    answer: 2,
  },
  {
    category: 'Investing',
    question: 'What does the Bank of Canada use to measure inflation?',
    choices: [
      'Consumer Price Index (CPI)',
      'GDP (Gross Domestic Product)',
      '5 year Fixed Rate Mortgage',
      'Prime rate',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'In a 2018 study "Do stocks outperform treasury bills" by Hendrik Bessembinder, what percentage of US stocks from 1926 - 2016 are responsible for $35 trillion of wealth creation?',
    choices: [
      'Slightly more than 1%',
      'Slightly more than 8%',
      'Slightly more than 10%',
      'Slightly more than 4%',
    ],
    answer: 3,
  },
  {
    category: 'Investing',
    question: 'In a 2018 study "Do stocks outperform treasury bills" by Hendrik Bessembinder, how many US stocks (out of 25,300) from 1926 - 2016 are responsible for 10% of wealth creation?',
    choices: [
      '5',
      '50',
      '500',
      '5000',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'In a 2018 study "Do stocks outperform treasury bills" by Hendrik Bessembinder, what is the most single frequent outcome (when returns are rounded to the nearest 5%) of individual US stocks in their lifetime from 1926 - 2016?',
    choices: [
      'Positive return of 5%',
      'Positive return of 3%',
      'Loss of 100%',
      'Loss of 1%',
    ],
    answer: 2,
  },
  {
    category: 'Investing',
    question: 'In the world of investing, what is an index?',
    choices: [
      'A group of securities, derivatives, or other financial instruments that represents and measures the performance of a specific market, asset class, market sector, or investment strategy',
      'A mutual fund',
      'An ETF (Exchange Traded Fund)',
      'The New York Stock Exchange (NYSE)',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'In the world of investing, who creates indices?',
    choices: [
      'Commercial Banks',
      'Central Banks',
      'Index Provider',
      'Stock Exchanges',
    ],
    answer: 2,
  },
  {
    category: 'Investing',
    question: 'TRUE OR FALSE: You can directly purchase the S&P 500 index fund from S&P Dow Jones',
    choices: [
      'TRUE - you just need to open an account with them',
      'FALSE - you cannot purchase an index fund directly from the index provider',
    ],
    answer: 1,
  },
  {
    category: 'Investing',
    question: 'When did John Bogle launch the first index mutual fund?',
    choices: [
      '1976',
      '1986',
      '1926',
      '1936',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'What are mutual funds and ETFs (Exchange Traded Funds)?',
    choices: [
      'Investment vehicles that are used to purchase a single stock/bond',
      'Investment vehicles that pool together funds from several investors and then purchase a basket of stocks/bonds',
      'Investment vehicles that are used to purchase GICs',
      'Investment vehicles that pool together funds from several investors and then buy life insurance',
    ],
    answer: 1,
  },
  {
    category: 'Investing',
    question: 'TRUE OR FALSE: Owning a company stock means you are a shareholder of the company.',
    choices: [
      'TRUE',
      'FALSE',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'TRUE OR FALSE: A bond is a loan made by an investor to a borrower, typically a corporation/government.',
    choices: [
      'TRUE',
      'FALSE',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'TRUE OR FALSE: When interest rates rise, bond prices fall.',
    choices: [
      'TRUE',
      'FALSE',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'TRUE OR FALSE: In terms of asset allocation, having bonds in a portfolio INCREASES its volatility.',
    choices: [
      'TRUE - generally, bonds are more volatile than stocks',
      'FALSE - generally, bonds are less volatile than stocks',
    ],
    answer: 1,
  },
  {
    category: 'Investing',
    question: 'TRUE OR FALSE: In terms of asset allocation, having stocks in a portfolio INCREASES its volatility.',
    choices: [
      'TRUE - generally, stocks are more volatile than bonds',
      'FALSE - generally, stocks are less volatile than bonds',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'Using https://larrybates.ca/t-rex-score if you invest $10,000 in a fund that returns 6.4% a year with fees (MER) of 1.75% for 25 years, how much of the gains are lost in fees?',
    choices: [
      '$1,005',
      '$10,005',
      '$6,005',
      '$16,005',
    ],
    answer: 3,
  },
  {
    category: 'Investing',
    question: 'Using https://larrybates.ca/t-rex-score if you invest $10,000 in a fund that returns 6.4% a year with fees (MER) of 0.25% for 25 years, how much of the gains are lost in fees?',
    choices: [
      '$1,693',
      '$2,693',
      '$5,693',
      '$10,693',
    ],
    answer: 1,
  },
  {
    category: 'Investing',
    question: 'What is compound interest?',
    choices: [
      'Interest earned from the original principal only',
      'Interest earned from the accumulated interest only',
      'Interest earned from the original principal plus accumulated interest',
    ],
    answer: 2,
  },
  {
    category: 'Investing',
    question: 'What is the rule of 72?',
    choices: [
      'An estimate for how long it will take for your money to double - 72 / interest rate',
      'An estimate for how long it will take to pay off your mortgage - 72 / interest rate',
      'An estimate for how long it will take for your money to double - interest rate / 72',
    ],
    answer: 0,
  },
  {
    category: 'Investing',
    question: 'According to SPIVA, as of Dec 31, 2021, approximately what % of Canadian Equity funds UNDERPERFORMED the S&P/TSX Composite Index over 10 years?',
    choices: [
      '50%',
      '70%',
      '80%',
      '90%',
    ],
    answer: 2,
  },
  {
    category: 'Investing',
    question: 'TRUE OR FALSE: You can accurately predict stock market returns based on the GDP growth rate of a country',
    choices: [
      'TRUE',
      'FALSE',
    ],
    answer: 1,
  },
  {
    category: 'Investing',
    question: 'According to data by TD and Bloomberg, $10,000 invested in the S&P/TSX Composite Index from 12/31/91 - 12/31/21, would have grown to $60,423.31 but if you missed the top 1% of trading days, how much would it be worth?',
    choices: [
      '$43,747.42',
      '$33,747.42',
      '$13,747.42',
      '$3,747.42',
    ],
    answer: 3,
  },
];

const registeredQs = [
  {
    category: 'Registered Accounts',
    question: 'What is the maximum RRSP contribution limit for 2022?',
    choices: [
      'unlimited, 18% of earned income',
      '$27,830',
      'unlimited',
      '$29,210',
    ],
    answer: 3,
  },
  {
    category: 'Registered Accounts',
    question: 'When can you start withdrawing from a personal RRSP?',
    choices: [
      'only starting at age 71',
      'Any time',
      'only starting at age 65',
      'You need to convert it to a RRIF before you can withdraw',
    ],
    answer: 1,
  },
  {
    category: 'Registered Accounts',
    question: 'How much can you over contribute to an RRSP without being penalized?',
    choices: [
      '$1,000',
      '$2,000',
      '$3,000',
      '$0',
    ],
    answer: 1,
  },
  {
    category: 'Registered Accounts',
    question: 'For which 2 plans can you withdraw funds from your RRSP with no withholding tax?',
    choices: [
      "Home Buyer's Plan, Lifelong Learning Plan",
      'First Time Car Buyer Plan, Lifelong Learning Plan',
      'Home Renovation Plan, Lifelong Learning Plan',
      "Home Buyer's Plan, Child Education Plan",
    ],
    answer: 0,
  },
  {
    category: 'Registered Accounts',
    question: 'In the year you turn 71 years old, which is NOT an option for your RRSP?',
    choices: [
      'convert it to a RRIF',
      'withdraw them',
      'purchase an annuity',
      'direct tranfer to TFSA',
    ],
    answer: 3,
  },
  {
    category: 'Registered Accounts',
    question: 'Assuming you were at least 18 years old and living in Canada in 2008, as of 2022, what is your lifetime TFSA contribution limit?',
    choices: [
      '$71,500',
      '$81,500',
      '$91,000',
      '$100,000',
    ],
    answer: 1,
  },
  {
    category: 'Registered Accounts',
    question: 'TRUE OR FALSE: You have to report TFSA withdrawals as income',
    choices: [
      'TRUE - same as RRSP withdrawals',
      "FALSE - TFSA withdrawals don't count as taxable income",
    ],
    answer: 1,
  },
  {
    category: 'Registered Accounts',
    question: 'TRUE OR FALSE: You can purchase stocks and bonds for your TFSA',
    choices: [
      'TRUE - you can open a self-directed TFSA account',
      'FALSE - you can only purchase GICs',
    ],
    answer: 0,
  },
  {
    category: 'Registered Accounts',
    question: 'What is the RRIF minimum withdrwal % limit at age 71?',
    choices: [
      '0',
      '5.28%',
      '2.50%',
      '4.00%',
    ],
    answer: 1,
  },
];

const generateQuestions = () => {
  return _.shuffle(insuranceQs.concat(emergencyQs, creditQs, goalQs, investQs, registeredQs));
};

export default generateQuestions;
