export const podcasts = [
  {
    name: 'Build Wealth Canada by Kornel Szrejber',
    url: 'https://www.buildwealthcanada.ca/blog/',
  },
  {
    name: 'More Money Podcast by Jessica Moorehouse',
    url: 'https://jessicamoorhouse.com/podcast/',
  },
  {
    name: 'Rational Reminder by Benjamin Felix and Cameron Passmore',
    url: 'https://rationalreminder.ca/podcast-directory',
  },
];

export const calculators = [
  {
    name: 'T-REX Score by Larry Bates - Find out how much fees you are actually paying for your investments',
    url: 'https://larrybates.ca/t-rex-score/',
  },
  {
    name: 'Compound Interest Calculator',
    url: 'http://www.moneychimp.com/calculator/compound_interest_calculator.htm',
  },
  {
    name: 'Portfolio Visualizer - help you make informed decisions when comparing and analyzing portfolios and investment products',
    url: 'https://www.portfoliovisualizer.com/',
  },
];

export const articles = [
  {
    name: 'Andrew Hallam, author of Millionaire Teacher',
    url: 'https://andrewhallam.com/',
  },
  {
    name: 'MoneySense',
    url: 'https://www.moneysense.ca/',
  },
  {
    name: 'Millennial Revolution - Stop working, Start living',
    url: 'https://www.millennial-revolution.com/',
  },
  {
    name: 'Savvy New Canadians - Save. Invest. Retire Early.',
    url: 'https://www.savvynewcanadians.com/',
  },
  {
    name: 'JL Collins - The Simple Path to Wealth',
    url: 'https://jlcollinsnh.com/manifesto/',
  },
];

export const videos = [
  {
    name: 'Ben Felix - Common Sense Investing',
    url: 'https://www.youtube.com/c/BenFelixCSI/videos',
  },
  {
    name: 'Justin & Shannon Bender - Canadian Portfolio Manager',
    url: 'https://www.youtube.com/c/JustinBenderCPM/videos',
  },
];

export const best = [
  {
    name: 'Best ETFs by MoneySense',
    url: 'https://www.moneysense.ca/save/investing/best-etfs-in-canada/',
  },
  {
    name: 'Best Online Brokers by MoneySense',
    url: 'https://www.moneysense.ca/save/investing/best-online-brokers-in-canada/',
  },
  {
    name: 'Best Credit Cards by MoneySense',
    url: 'https://www.moneysense.ca/spend/credit-cards/best-credit-cards-in-canada/',
  },
  {
    name: 'Ratehub.ca - best rates of various financial products',
    url: 'https://www.ratehub.ca/',
  },
  {
    name: 'Top 100 Rated Charities by Charity Intelligence',
    url: 'https://www.charityintelligence.ca/charity-profiles/top-100-rated-charities-2',
  },
];

export const books = [
  {
    name: 'Millionaire Teacher',
    author: 'Andrew Hallam',
    libUrl: 'https://share.libbyapp.com/title/2937065',
  },
  {
    name: 'Balance',
    author: 'Andrew Hallam',
    libUrl: 'https://share.libbyapp.com/title/7619302',
  },
  {
    name: 'Quit Like a Millionarie',
    author: 'Kristy Shen, Bryce Leung',
    libUrl: 'https://share.libbyapp.com/title/4489361',
  },
  {
    name: 'Wealthing Like Rabbits',
    author: 'Robert R. Brown',
    libUrl: 'https://share.libbyapp.com/title/4620564',
  },
  {
    name: 'Think, Act, and Invest Like Warren Buffett',
    author: 'Larry Swedroe',
    libUrl: 'https://share.libbyapp.com/title/1201854',
  },
  {
    name: 'Beat the Bank',
    author: 'Larry Bates',
    libUrl: 'https://share.libbyapp.com/title/4302060',
  },
  {
    name: 'A Random Walk Down Wall Street',
    author: 'Burton G. Malkiel',
    libUrl: 'https://share.libbyapp.com/title/4127083',
  },
  {
    name: 'Reboot Your Portfolio',
    author: 'Dan Bortolotti',
    libUrl: 'https://calgary.bibliocommons.com/v2/record/S95C1440236',
  },
];