import { CopyrightOutlined, MailOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Layout } from 'antd';
import React, { ReactElement } from 'react';
import styles from './Footer.module.scss';

const FooterBar = (): ReactElement => {
  const { Footer } = Layout;
  const disclaimerModal = () => {
    Modal.info({
      title: 'Disclaimer',
      content: (<article><p>I am not a finance professional. The resources here are intended for educational purposes only. It is not intended to provide personalized financial advice. I cannot guarantee the information presented will be 100% accurate. I am not affiliated with any of the resources listed in the favorites list and in the learning guide.</p>
      <p>
      If you need help with your personal financial situation, please seek assistance from certified professionals (e.g. tax accountants, financial planners/advisors, etc.).</p>
      </article>),
    });
  };

  return (
    <Footer style={{ display: 'flex', flexDirection: 'column', 
      alignItems: 'center', justifyContent: 'center', bottom: '0', width: '100%' }}>
      <span>Copyright <CopyrightOutlined /> 2023 Unicorn Money</span>
      <span className={styles.contact}><MailOutlined /> <a href="mailto:unicornmoneydad@protonmail.com"><Button type="text">Contact</Button></a></span>
      <span className={styles.disclaimer}><InfoCircleOutlined /> <Button type="text" onClick={disclaimerModal}>Disclaimer</Button></span>
    </Footer>
  );
};

export default FooterBar;
