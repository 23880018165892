import { Divider } from 'antd';
import React from 'react';
import styles from './Feedback.module.scss';

const Feedback = () => {
  return (
    <div className={styles.contact}>
      <Divider className={styles.contactTitle}>FEEDBACK</Divider>
      <p>I'd love to hear your feedback! What do you like about the quiz or learning resources?</p>
        <p>What can be improved?</p> 
        <p>What topics would you like to see?</p>
      <form id="contact" name="contact" method="POST" data-netlify="true" data-netlify-recaptcha="true">
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label htmlFor="name">Name:</label>
          <input type="text" name="name" required />
        </p>
        <p>
          <label htmlFor="email">Email:</label>
          <input type="email" name="email" required />
        </p>
        <p>
          <label htmlFor="message">Message:</label>
          <textarea name="message" rows={10} required />
        </p>
        <div data-netlify-recaptcha="true"></div>
        <p>
          <input type="submit" value="SUBMIT" className={styles.submitBtn} />
        </p>
      </form>
    </div>
  );
};

export default Feedback;
