import { Radio, RadioChangeEvent, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styles from './QuizItem.module.scss';

export type QuizItemType = {
  questionNumber: number;
  question: string;
  choices: string[];
  selectedChoice: number;
  correctAnswer: number;
  showAnswer: boolean;
  onClick: (value: number) => void;
};

const QuizItem = ({
  question,
  choices,
  selectedChoice,
  correctAnswer,
  showAnswer,
  onClick,
}: QuizItemType) => {
  const [answer, setAnswer] = useState(-1);
  const [radioValue, setRadioValue] = useState(-1);
  const options = choices.map((item, index) => {
    return selectedChoice === -1 ? (
      <Radio value={index} key={item}>
        {index + 1}. {item}
      </Radio>
    ) : (
      <Radio value={index} key={item} disabled={selectedChoice !== index}>
        {index + 1}. {item}
      </Radio>
    );
  });
  const onRadioChange = (event: RadioChangeEvent) => {
    setRadioValue(event.target.value);
    onClick(event.target.value);
  };

  useEffect(() => {
    setRadioValue(-1);
  }, [question]);

  useEffect(() => {
    setAnswer(selectedChoice);
  }, [selectedChoice]);
  
  return (
    <div className={styles.quizItemContainer}>
      <p>{question}</p>
      <Radio.Group onChange={onRadioChange} value={radioValue}>
        <Space direction="vertical">{options}</Space>
      </Radio.Group>
      {showAnswer && (
        <div className={styles.answerSection}>
          {answer === correctAnswer ? (
            <p className={styles.correctAnswer}>
              <CheckCircleOutlined /> Your answer is correct!
            </p>
          ) : (
            <p className={styles.wrongAnswer}>
              <CloseCircleOutlined /> Your answer is incorrect! The correct
              answer is option #{correctAnswer + 1}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizItem;
